import { BlitzPage } from "@blitzjs/next"
import { Suspense } from "react"
import Layout from "src/core/layouts/Layout"
import { FocusSessionProvider } from "src/focus-session/focus-session.context"
import ObjectivesSection from "src/objectives/components/ObjectivesSection"
import { ObjectivesProvider } from "src/objectives/objectives.context"

const ObjectivesPage: BlitzPage = () => {
  return (
    <div className="max-w-2xl mx-auto sm:px-6 lg:px-8">
      <Suspense fallback={<></>}>
        <ObjectivesProvider>
          <FocusSessionProvider>
            <ObjectivesSection />
          </FocusSessionProvider>
        </ObjectivesProvider>
      </Suspense>
    </div>
  )
}

ObjectivesPage.getLayout = (page) => (
  <Layout title={process.env.APP_NAME + " - Do anything"}>{page}</Layout>
)

export default ObjectivesPage
